import React from "react";
import image from "../assets/images/haat.jpg";

function Content({ title, text }) {
  return (
    <section className="bg-gray-200">
      <div className="container">
        <div className="py-8 md:py-12 text-left">
          <img src={image} className="block rounded-lg" alt={title} />
          <div className="bg-white mt-8 px-8 md:px-10 py-8 rounded-lg">
            <p className="text-md md:text-xl mb-4 tracking-wide font-suse">
              नेपाल सरकारले नेपालको संविधान, २०७२ बमोजिम स्थानीय निकायहरुको
              पुनर्संरचनाको लागि गठन गरेको उच्चस्तरीय आयोगको २०७३ पुष २२ गतेको
              प्रतिवेदनलाई आधार लिई मिति २०७३ फागुन २७ गतेबाट स्थानीय तह लागू
              गर्ने निर्णय गरेको हो । जस अनुसार सिन्धुली जिल्लाको पश्चिमी
              क्षेत्रमा अबस्थित साबिक कुशेश्वोर दुम्जा , झागाझोली रातमाटा ,
              पुरानो झागाझोली, सित्तलपाटी र मझुवा ५ गा.वि.स. समेटी रथापना गरिएको
              क्षेत्र नै सुनकोशी गाउँपालिका हो । यस गाउँपालिकाको नामाकरण नेपालको
              प्रसिद्ध ठुलो नदि सप्तकोशीको सहायक नदि सुनकोशीलाई आधार मानी गरिएको
              छ । पूर्वमा गोलान्जोर गाउँपालिकाको सीमा क्षेत्र, पश्चिममा काभ्रे
              जिल्लाको रोशी गाउँपालिका, उत्तरमा रामेछाप जिल्लाको खाडादेवी
              गाउँपालिका र दक्षिणमा घ्याँग्लेख गाउपालिका र कमलामाई नगरपालिका
              सीमा क्षेत्र फैलीएको यस गाउँपालिकाको भौगालिक कुल क्षेत्रफल १५४.६८
              बर्ग कि.मि. र वडा सङ्ख्या ७ रहेको छ । भौगालिक धरातलीय रुपमा ससाना
              टार देखि खोला खोल्सी, अग्लो होचो डाँडा काँडा हुदै महाभारत शृंखला
              सम्मको भूभाग रहेको छ । यस गाउँपालिकाको विभिन्न भागबाट खोला नालाहरु
              भागी सुनकोशी नदीमा समाहित भएका छन l तिनै खोलानालाहरुलाई आधार मानि
              वडाहरुको प्राय सिमांकन कायम भएको छ । यहाको हावापानी नेपालको हिमाल
              पहाड तराइ जस्तै तिन प्रकारको रहेको पाइन्छ l यस गाउँपालिका २७.४१८१
              डिग्री उतर अक्षरंश र ८५.८६७५ डिग्री पुर्व देशान्तरमा रहेको छ l
            </p>
            <p className="text-md md:text-xl mt-4 mb-6 tracking-wide font-suse">
              मानवीय रुपमा यस गाउँपालिकामा ब्राह्मण ,क्षेत्री ,तामांग, मगर,
              नेवार, विश्वकर्मा, दमाई, सार्की, माझी, हायु, सुनुवार लगायतका
              विभिन्न जातिहरु बसोबास रहेको छ l यस गाउँपालिकाको कुल जनसङ्ख्या
              १८३७५ छ । मानवीय चेतना तथा भौतिक, आर्थिक र पूर्वाधार विकासको
              दृष्टिकोणबाट यस गाउँपालिका पिछडिएको देखिन्छ । पर्यटकीय हिसाबले
              कुशेश्वर महादेव मन्दिर, फापर चुली, वि.पी पार्क , सहिद स्मारक,
              सुनकोशी नदीमा जलयात्रा गर्ने र विभिन्न मठमन्दिर, गुम्बाले महत्व
              बोकेको स्थानको रुपमा रहेको छ।
            </p>
            <p className="text-md md:text-xl mt-4 mb-6 tracking-wide font-suse">
              दक्षिण तर्फ महाभारत श्रृखला र उत्तर तर्फ सुनकोशी नदिको बीचमा
              अवस्थित यस गाउँपालिका जैविक विविधता र प्राकृतिक श्रोतका हिसावले
              महत्वपूर्ण रहेको छ । साना ठूला खोला, खहरेबाट सिञ्चित भइ आएको यो
              क्षेत्र परापूर्वक काल देखि नै अन्न र पशुजन्य उत्पादनको प्रचुर
              सम्भावना बोकेको छ। परम्परागत कृषिमा निर्भर रहेको यो क्षेत्रमा हाल
              पशुपालन, तरकारी तथा फलफूलको व्यवसायीक खेती गर्ने प्रचलन बढ्दै गएको
              छ र सोही अनुरुपमा माग पनि बढ्दै गएको छ । यहाँ उपलब्ध जैविक विविधता
              र प्राकृतिक श्रोतको अधिकतम उपयोग गरी कृषि व्यवसायीकरण मार्फत
              तुलनात्मक लाभका बालीहरुको उत्पादन तथा उत्पादकत्व वृद्दि गरी
              स्वरोजगार, रोजगार र कृषि व्यवसायी बनाउने लक्ष्य अनुरुप दीर्घकालीन
              र अल्पकालीन योजना तर्जुमा तयार गर्ने कार्य भइरहेको छ । यस
              गाउँपालिकामा प्रधानमन्त्री कृषि आधुनिकीकरण परियोजना अन्तरगत पकेट
              क्षेत्र र ब्लकका कार्यक्रम सञ्चालन भइरहेको छ, साथै कृषि जोन
              कार्यक्रम सञ्चालनमा रहेको छ । यहाको मुख्य पेशा कृषि नै हो ।
            </p>

            <p className="text-md md:text-xl mt-4 mb-6 tracking-wide font-suse">
              यस गाउँपालिकामा १२ वटा स्वास्थ्य संस्था रहनुका साथै ४५ वटा
              सामुदायिक विद्यालय रहेको छ l
            </p>

            <h3 className="text-xl md:text-2xl font-semibold tracking-wider font-primary w-2/3">
              हाटबजारको विशेषता:
            </h3>
            <ul className="text-md md:text-xl mt-4 ms-8 tracking-wide font-suse list-disc">
              <li>ताजा र स्वस्थ: प्रत्येक सामग्री किसानको खेतबाट सिधा।</li>
              <li>सस्तो र गुणस्तरीय: बजारभन्दा सस्तोमा।</li>
              <li>तुरुन्त डेलिभरी: तपाईंको घरमै समयमै डेलिभर।</li>
              <li>सजिलो प्रयोग: मोबाइलबाटै केही क्लिकमा अर्डर।</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
