import React, { useEffect } from "react";
import { Table, Tag, Button, Spin, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders, updateOrderStatus } from "../../redux/orderSlice"; // Adjust the import based on your file structure
import { fetchStocks } from "../../redux/stockSlice"; // Add this import
import pro from "../../assets/images/housekeeper.png";

const Order = () => {
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector((state) => state.order); // Select orders from the state
  const { stocks } = useSelector((state) => state.stock); // Select stocks from the state

  useEffect(() => {
    dispatch(fetchOrders()); // Fetch orders when the component mounts
    dispatch(fetchStocks()); // Fetch stocks when the component mounts
  }, [dispatch]);

  const handleUpdateStatus = (orderId, newStatus) => {
    dispatch(updateOrderStatus({ orderId, status: newStatus }));
  };

  if (loading) {
    return <Spin tip="Loading orders..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const columns = [
    {
      title: "अर्डर आईडी",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "खरिद गर्नेको नाम",
      dataIndex: "buyerName",
      key: "buyerName",
    },
    {
      title: "सम्पर्क",
      dataIndex: "buyerContact",
      key: "buyerContact",
    },
    {
      title: "ठेगाना",
      dataIndex: "buyerAddress",
      key: "buyerAddress",
    },
    {
      title: "कुल मूल्य",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "अवस्था",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color="green">{status}</Tag>,
    },
    {
      title: "क्रिया",
      key: "action",
      render: (_, record) => (
        <div>
          <Popconfirm
            title="Are you sure you want to mark this order as completed?"
            onConfirm={() => handleUpdateStatus(record.id, "complete")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" className="mr-2">
              Complete Order
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to cancel this order?"
            onConfirm={() => handleUpdateStatus(record.id, "canceled")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Cancel Order</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // Prepare data for the table
  const dataSource = orders.map((order) => ({
    id: order.id,
    buyerName: order.person.name,
    buyerContact: order.person.phone,
    buyerAddress: order.person.current_address,
    totalPrice: `${order.total_price} रुपैयाँ`,
    status: order.status,
  }));

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      pagination={{ pageSize: 5 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default Order;
