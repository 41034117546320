import React, { useState } from "react";
import { Card, Progress } from "antd";
import { Line, Bar } from "@ant-design/charts";

const Dashboard = () => {
  const barData = [
    { type: "फूलगोभी", sales: 200 },
    { type: "आलु", sales: 80 },
    { type: "टमाटर", sales: 150 },
    { type: "गाजर", sales: 300 },
  ];

  const barConfig = {
    data: barData,
    height: 400,
    xField: "sales",
    yField: "type",
    seriesField: "type",
    colorField: "type",
    barWidthRatio: 0.8,
    title: {
      text: "Product Sales Bar Graph",
    },
  };

  return (
    <>
      <Card title="Product Sales Bar Graph" className="mt-6">
        <Bar {...barConfig} />
      </Card>
    </>
  );
};

export default Dashboard;
